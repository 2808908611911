import React, { Component } from 'react'
import avatar from './../../avatar.jpg'
import Typed from 'react-typed'
import './AppBar.css'


class AppBar extends Component {
    render() {
        return (
            <div className="App-Bar">
                <img src={avatar} className="App-Avatar" alt="Leo Marcotte" />
                <Typed
                    className="App-Caption"
                    strings={[
                        'My name is <strong>Leo</strong>',
                        'I\'ve been developping <strong>iOS apps</strong> since <strong>2015</strong>',
                        'I love <strong>turtles</strong> and I mostly drink <strong>tea</strong>^700'
                        // 'Here are cool stuff I like to use in my project: <strong>Cocoapods</strong>^700',
                        // 'Here are cool stuff I like to use in my project: <strong>RxSwift</strong>^700',
                        // 'Here are cool stuff I like to use in my project: <strong>RxCocoa</strong>^700',
                        // 'Here are cool stuff I like to use in my project: <strong>Apollo</strong>^700',
                        // 'Here are cool stuff I like to use in my project: <strong>Alamofire</strong>^700',
                        // 'Here are cool stuff I like to use in my project: <strong>Fastlane</strong>^700',
                    ]}
                    cursorChar={''}
                    typeSpeed={20}
                    backSpeed={10}
                    smartBackspace={true}
                    loop={true}
                />
            </div>
        )
    }
}

export default AppBar