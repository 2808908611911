import React, { Component } from 'react';
import RoundChip from './../../View components/Chip/RoundChip'
import Card from './../../View components/Card/Card'


class SkillsSection extends Component {
  render() {
    return (
      <div className="Section">
        <h1>Skills</h1>
        {this.renderItems()}
      </div>
    )
  }

  renderItems() {
    return this.props.data.map((item, index) => {
      return <Skill key={index} data={item} />
    })
  }
}

class Skill extends Component {
  handleSection() {
    if (this.props.data.section === "iOS") {
      return this.renderIOSSection()
    } else if (this.props.data.section === "Desktop") {
      return this.renderDesktopSection()
    } else if (this.props.data.section === "Web") {
      return this.renderWebSection()
    }
  }

  renderIOSSection() {
    return (
      <div>
        <h4 style={{ marginTop: '40px' }}>Languages:</h4>
        <div className="ChipsCloud">
          <RoundChip link="https://swift.org/" text="Swift" />
        </div>
        <h4 style={{ marginTop: '40px' }}>Patterns:</h4>
        <div className="ChipsCloud">
          <RoundChip text="MVVMC" />
          <RoundChip text="MVVM" />
          <RoundChip text="MVC" />
          <RoundChip text="Coordinators" />
        </div>
        <h4 style={{ marginTop: '40px' }}>Frameworks:</h4>
        <div className="ChipsCloud">
          <RoundChip link="https://github.com/ReSwift/ReSwift" text="ReSwift" />          
          <RoundChip link="https://github.com/ReactiveX/RxSwift" text="RxSwift" />
          <RoundChip link="https://github.com/ReactiveX/RxSwift/tree/master/RxCocoa" text="RxCocoa" />
          <RoundChip link="https://github.com/Alamofire/Alamofire" text="Alamofire" />
          <RoundChip link="https://github.com/apollographql/apollo-ios" text="Apollo / GraphQL" />
          <RoundChip link="https://github.com/realm/realm-cocoa" text="Realm" />
          <RoundChip link="https://github.com/SwiftGen/SwiftGen" text="SwiftGen" />
          <RoundChip link="https://github.com/realm/SwiftLint" text="SwiftLint" />
          <RoundChip link="https://github.com/lokalise/lokalise-ios-framework" text="Lokalise" />
          <RoundChip link="https://github.com/facebook/facebook-objc-sdk" text="FacebookSDK" />
          <RoundChip link="https://github.com/googlemaps/google-maps-ios-utils" text="GoogleMap" />
          <RoundChip link="https://github.com/Mangopay/cardregistration-ios-kit" text="MangoPay" />
        </div>
        <h4 style={{ marginTop: '40px' }}>Tools:</h4>
        <div className="ChipsCloud">
          <RoundChip link="https://fastlane.tools" text="Fastlane" />
          <RoundChip link="https://cocoapods.org" text="Cocoapods" />
          <RoundChip link="https://firebase.google.com/" text="Firebase" />
          <RoundChip link="https://uxcam.com/" text="UXCam" />
        </div>
      </div>
    )
  }

  renderDesktopSection() {
    return (
      <div>
        <h4 style={{ marginTop: '40px' }}>Languages:</h4>
        <div className="ChipsCloud">
          <RoundChip text="C# / WPF" />
          <RoundChip text="C# / Winform" />
          <RoundChip text="Electron.js" />
          <RoundChip text="C" />
          <RoundChip text="C++" />
        </div>
      </div>
    )
  }

  renderWebSection() {
    return (
      <div>
        <h4 style={{ marginTop: '40px' }}>Languages:</h4>
        <div className="ChipsCloud">
          <RoundChip text="HTML" />
          <RoundChip text="CSS" />
          <RoundChip text="React.js" />
          <RoundChip text="SQL" />
          <RoundChip text="Golang" />
        </div>
        <h4 style={{ marginTop: '40px' }}>Tools:</h4>
        <div className="ChipsCloud">
          <RoundChip text="APACHE" />
          <RoundChip text="Grunt" />
          <RoundChip text="Webpack" />
          <RoundChip text="NPM" />
          <RoundChip text="Yarn" />
        </div>
      </div>
    )
  }

  render() {
    return (
      <Card
        title={this.props.data.section}
        description={this.renderSubsection}
      >
        {this.handleSection()}
      </Card>
    )
  }

  renderSubsection() {
    return this.props.data.skills.map((item) => {
      return (
        <div>
          <b>{item.title}:</b>
          <p>{item.description}</p>
        </div>
      )
    })
  }
}

export default SkillsSection;