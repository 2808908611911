import React, { Component } from 'react'
import Card from './../../View components/Card/Card'

class StudiesSection extends Component {
  render() {
    return (
      <div className="Section">
        <h1>Education</h1>
        {this.renderItems()}
      </div>
    )
  }

  renderItems() {
    return this.props.data.map((item, index) => {
      return <Study key={index} data={item} />
    })
  }
}

class Study extends Component {
  render() {
    return (
      <Card className="Study"
        title={this.props.data.title}
        subtitle={this.props.data.subtitle}
        time={this.props.data.time}
        description={this.props.data.description}
      />
    )
  }
}

export default StudiesSection