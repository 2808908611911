import React from 'react'
import './ApplicationsTray.css'

class ApplicationsTray extends React.Component {
    
    render() {
        return (
            <div className="ApplicationsTray">{this.props.children}</div>
        )
    }
}

export default ApplicationsTray