import React from 'react'
import "./Application.css"

class Application extends React.Component {
    render() {
        return (
            <a target="_blank" rel="noopener noreferrer" href={this.props.appLink}>
                <div className="Application">
                    <img src={this.props.appIcon} alt="" />
                    <p><i>{this.props.appName}</i></p>                
                </div>
            </a>
        )
    }
}

export default Application;