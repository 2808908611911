import React, { Component } from 'react'
import './Chip.css'

class Chip extends Component {
    constructor(props) {
        super(props)

        this.state = {
            style: {
                // background: `linear-gradient(to bottom right, ${this.props.colors[0]}, ${this.props.colors[1]}, ${this.props.colors[2]}`,
                backgroundAttachment: 'fixed'
            }
        }
    }

    render() {
        return (
            <p className="Chip" style={this.state.style}>{this.props.text}</p>
        )
    }
}

export default Chip;