import React, { Component } from 'react'
import './Card.css'
import Chip from './../Chip/Chip'

class Card extends Component {
    render() {
        if (!this.props.time) {
            return (
                <div className="Card">
                    <div className="ProfessionalExperienceHeader">
                        <div className="CardTitle">
                            <h3>{this.props.title}</h3>
                            <i>{this.props.subtitle}</i>
                        </div>
                    </div>
                    <p>{this.props.description}</p>
                    {this.props.children}
                </div>
            )
        } else {
            return (
                <div className="Card">
                    <div className="ProfessionalExperienceHeader">
                        <div className="CardTitle">
                            <h3>{this.props.title}</h3>
                            <i>{this.props.subtitle}</i>
                        </div>
                        <Chip text={this.props.time} />
                    </div>
                    <p>{this.props.description}</p>
                    {this.props.children}
                </div>
            )
        }
    }
}

export default Card