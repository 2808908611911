import React, { Component } from 'react'
import StudiesSection from './Sections/Studies/Studies.js'
import BackgroundSection from './Sections/Background/Background.js'
import SkillsSection from './Sections/Skills/Skills.js'
import './App.css'
import Separator from './View components/Separator/Separator'
import AppBar from './View components/AppBar/AppBar'

var skillsJson = require('./data/skills.json')
var backgroundJson = require('./data/background.json')
var studiesJson = require('./data/studies.json')

class App extends Component {
  constructor() {
    super()

    this.state = {
      skills: skillsJson,
      background: backgroundJson,
      studies: studiesJson
    }
  }

  render() {
    return (
      <div className="App">
        <AppBar />
          <BackgroundSection data={this.state.background} />
          <StudiesSection data={this.state.studies} />
          <SkillsSection data={this.state.skills} />
          <Separator />
          <div>
            <p>This CV has been entirely made by me using ReactJs, HTML and CSS, all the data is stored in json files</p>
          </div>
      </div>
    );
  }
}

export default App