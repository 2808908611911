import React, { Component } from 'react'
import Card from './../../View components/Card/Card'
import Application from '../../View components/Application/Application'
import ApplicationsTray from '../../View components/ApplicationsTray/ApplicationsTray'

class BackgroundSection extends Component {
  render() {
    return (
      <div className="Section">
        <h1>Work Experience</h1>
        {this.renderItems()}
      </div>
    )
  }

  renderItems() {
    return this.props.data.slice(0).reverse().map((item, index) => {
      return (
      <Card
        key={index}
        title={item.title}
        subtitle={item.position}
        time={item.time}
        description={""}
      >
        <div dangerouslySetInnerHTML={ {__html: item.description} } ></div>
        <ApplicationsTray>
          {item.applications.map((application, index) => {
            return (<Application key={index} appLink={application.link} appIcon={application.appIcon} appName={application.name} />)
          })}
        </ApplicationsTray>
      </Card>
      )
    })
  }
}

export default BackgroundSection;