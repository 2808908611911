import React, { Component } from 'react'
import './Chip.css'

class RoundChip extends Component {
    render() {
        if (this.props.link) {
            return (
                <a target="_blank" rel="noopener noreferrer" href={this.props.link} className="RoundChip">{this.props.text}</a>
            )
        } else {
            return (
                <p className="RoundChip">{this.props.text}</p>
            )
        }
    }
}

export default RoundChip