import React, { Component } from 'react'
import './Separator.css'

class Separator extends Component {
    constructor(props) {
        super(props)

        this.state = {
            style: {
                // background: `linear-gradient(to bottom right, ${this.props.colors[0]}, ${this.props.colors[1]}, ${this.props.colors[2]}`,
                backgroundAttachment: 'fixed'
            }
        }
    }
    
    render() {
        return (
            <hr className="Separator" />
        )
    }
}

export default Separator